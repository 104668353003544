/* eslint-disable @typescript-eslint/no-var-requires */
const path = require('path');

module.exports = {
  i18n: {
    locales: ['en', 'ar'],
    defaultLocale: 'ar',
    localePath: path.resolve('./public/locales'),
    localeDetection: false,
    returnObjects: true,
  },
  trailingSlash: true,
  react: { useSuspense: false },
};
